export interface Video {
  id: number;
  eventId: number;
  homeTeamId: number;
  awayTeamId: number;
  userId: number;
  approved: boolean;
  pendingDeletion: boolean;
  slug: string;
  scheduleKey: string;
  title: string;
  eventName: string;
  homeTeamName: string;
  awayTeamName: string;
  videoLink: string;
}
export interface Document {
  id: number;
  title: string;
  file?: {
    url: string;
    s3Bucket: string;
    s3Key: string;
    fileName: string;
  } | null;
  private?: boolean;
}

export interface EventList {
  id: Number;
  title: string;
  organizationIdList: number[];
  countryList: string[];
  eventIdList: number[];
}

export interface EventChecklist {
  id: Number;
  eventId: number;
  todos: Todo[];
  event: Event;
}

export interface Match {
  round: number;
  match: number;
  player1: string | number | null;
  player2: string | number | null;
  win?: {
    round: number;
    match: number;
  };
  loss?: {
    round: number;
    match: number;
  };
}

export interface EventUpdate {
  tournaments: boolean;
  teams: boolean;
  schedule: boolean;
  event: boolean;
  singleTeam: number; //id
  referees: boolean;
  skipScheduleUpdate: boolean;
}

export interface PageSection {
  type:
    | 'threeImages'
    | 'basicContent'
    | 'threeIcons'
    | 'map'
    | 'imageLeft'
    | 'imageRight'
    | 'imageBelow'
    | 'imageTop'
    | 'fullImage'
    | 'fullVideo';
  columnStart: number;
  columnEnd: number;
  id: number;
  order: number;
  content: PageSectionContent[];
}

export interface PageSectionContent {
  title?: string;
  subTitle?: string;
  text?: string;
  image?: { fileName?: string; type?: 'icon' | 'img' } | null;
  items?: PageSectionContent[];
  content?: PageSectionContent[];
  map?: { url?: string };
  youtube?: { url?: string };
}

export interface Event {
  address: any;
  name: string;
  slug: string;
  isEventManager?: boolean;
  published?: boolean;
  registrationOpen?: boolean;
  planner?: boolean;
  divisions: number[];
  ballType: number[];
  startDate: string | null;
  endDate: string | null;
  paymentType: 'manual' | 'card';
  currency: string;
  paymentEmail: string;
  pricePerTeam: number;
  documents?: { [key: number]: Document };
  requiredDocuments?: {
    text: string;
    documentId?: number;
    tournamentIds: number[];
    isUploaded?: boolean;
    selectedFile?: any;
  }[];
  type: 'tournament' | 'league' | 'drop-in' | 'combine' | 'practice' | 'clinic';
  contactMethods?: ContactMethod[];
  creatorEmail: string;
  organizationId?: number;
  coHostOrganizationId?: number;
  approved?: boolean;
  coHostApproved?: boolean;
  country?: { name: string; code: string };
  region?: { name: string; code: string };
  countryCode?: string;
  isPast?: boolean;
  countryName?: string;
  regionCode?: string;
  regionName?: string;
  weekDays?: string[];
  contactConsent?: boolean;
  marketingConsent?: boolean;
  image?: {
    url: string;
    s3Bucket: string;
    s3Key: string;
    fileName: string;
  } | null;
  originalStartDate?: string | null;
  originalEndDate?: string | null;
  description?: string;
  aboutPage?: PageSection[];
  streamUrl?: string;
  website?: string;
  signupUrl?: string;
  distance: number | null;
  schedulePublished: Boolean;
  mvpsPublished: Boolean;
  teamsPublished: Boolean;
  refsPublished: Boolean;
  hostHotel?: any;
  schedule?: EventSchedule;
  eventSchedule?: EventSchedule['schedule'];
  teams?: EventTeam[];
  referees?: EventReferee[];
  tournaments?: Tournament[];
  todos?: Todo[];
  lat: number;
  lon: number;
  id: number;
  timezone: string;
}

export type FormState = Omit<
  Event,
  | 'lat'
  | 'lon'
  | 'distance'
  | 'type'
  | 'todos'
  | 'tournament'
  | 'tournaments'
  | 'id'
  | 'slug'
  | 'schedulePublished'
  | 'teamsPublished'
  | 'mvpsPublished'
  | 'refsPublished'
  | 'paymentType'
  | 'currency'
  | 'paymentEmail'
  | 'pricePerTeam'
> & {
  type: Event['type'] | undefined;
  contactConsent?: boolean;
  marketingConsent?: boolean;
  id?: number;
};

export type FiltersState = Partial<
  Omit<FormState, 'type'> & {
    type: string[];
    distance: number;
    countries: string[];
    streamed: boolean;
    organizations: number[];
  }
>;

export interface FeatureFlag {
  id?: number;
  name: string;
  value: string;
  conditionValue?: number | string[];
  valueType: 'boolean' | 'string';
  type?: 'rolloutPercentage' | 'userIds';
}

export type SimpleFeatureFlag = Pick<FeatureFlag, 'id' | 'name' | 'value' | 'valueType'>;

export interface Member {
  id?: number;
  player?: Player;
  referee?: Referee;
  team?: Team;
  organization?: Organization;
  expiration: Date;
  createdAt: Date;
  activationSecret: string;
  firstName: string;
  lastName: string;
  type: 'player' | 'team' | 'organization' | 'referee';
  approved: boolean;
}

export interface Organization {
  id?: number;
  name: string;
  slug?: string;
  nickname?: string;
  website?: string;
  events?: Event[];
  members?: Member[];
  reformattedMembers?: { [key: string]: Member[] };
  address?: any;
  approved?: boolean;
  features?: string[];
  countryCode?: string;
  countryName?: string;
  regionCode?: string;
  regionName?: string;
  contactMethods?: ContactMethod[];
}

export interface UserOrganization {
  organizationId: number;
  userId: number;
  organization: Organization;
  approved?: boolean;
}

export interface ContactMethod {
  type: 'facebook' | 'instagram' | 'whatsapp' | 'phone' | 'email' | 'website';
  value: string;
}

export interface User {
  id?: number;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  location?: string;
  birthdate?: string;
  contactMethods?: ContactMethod[];
  plan?: {
    id: string;
    accessLevel: string;
    name: string;
  };
  isConfirmed: boolean;
  player?: Player;
  referee?: Referee;
}

export interface Referee {
  id: number;
  ballTypeIds?: number[];
  user: User;
}

export interface Player {
  id: number;
  number: number;
  user: User;
}

export interface TeamPlayer {
  id?: number;
  teamId?: number;
  playerId?: number;
  firstName: string;
  lastName: string;
  role?: string;
  location?: any;
  birthdate?: string;
  number?: number;
}

export interface OrgUser {
  user: User;
  organization: Organization;
  userId: number;
  approved?: boolean;
  organizationId: number;
}

/* type ImageTransformation = Partial<{
  top: number;
  left: number;
  zoom: number;
  width: number;
  height: number;
  originalDimensions: { width: number; height: number };
}>;

type ImageObject = {
  url?: string;
  id?: string;
  transformation?: ImageTransformation;
  isLink?: boolean;
  deleted?: ImageObject[];
} | null; */

export interface Division {
  id: number;
  title: string;
}

export interface EventFormData {
  event: Event | null;
  handleSave: (formState: Partial<FormState>, callback?: () => void) => void;
}

export interface Todo {
  id: number;
  title: string;
  description?: string;
  completed: boolean;
  dueDate?: string;
  order: number;
  todos?: Todo[];
}

export interface TeamStandingData {
  [key: string]: {
    win: number;
    loss: number;
    tie: number;
    gamePlayed: number;
    pointsWon: number;
    pointsLost: number;
    diff: number;
    totalPoints: number;
  };
}

export interface EventTeamsById {
  [key: number | string]: EventTeam;
}

export interface EventPlayersById {
  [key: number]: TeamPlayer;
}

export interface ScheduleChanges {
  [key: string]: { rowIndex: number; matchIndex: number };
}

export interface Item {
  label: string;
  divisionId: number;
  tournamentId: number;
  pool?: number;
  id: string;
}

export interface Group {
  order: number;
  courts: number[];
  items: Item[];
}

export type PlayoffStages = 'final' | 'semis' | 'quarters' | 'default';

export type Brackets = { [tournamentId: string]: Match[][] };
export type NewBrackets = { loser: Brackets; winner: Brackets };

export interface EventSchedule {
  id: number;
  eventId: number;
  numberOfCourts: number;
  bufferTime: number;
  playoffBufferTime: number;
  brackets?: NewBrackets | Brackets;
  gameTime: number;
  numberConcurrentCourtPerTeam?: number;
  playoffGameTime: { [key in PlayoffStages]?: number };
  scheduleChanges: ScheduleChanges;
  tiedStandings?: { [id: string]: { team1Id: number; team2Id: number; won?: number; tournamentId: number } };
  matchupsChangedManually?: boolean;
  manualScheduling?: boolean;
  globalClock?: boolean;
  schedule?: { [key: string]: Matchup[] };
  mvps: {
    [tournamentId: number]: {
      [gameId: string]: {
        player1Mvp1?: number;
        player1Mvp2?: number;
        player2Mvp1?: number;
        player2Mvp2?: number;
      };
    };
  };
  timesPerId?: {
    [key: string]: { duration: number; gameTime: number; bufferTime: number; date: string; time: string };
  };
  scheduleOrder?: { [key: string]: Group };
  scheduleRefs?: ScheduleRefs;
  scores?: Scores;
  sortedTeams?: { [key: number]: TeamId[] };
  groupStandings?: { [key: number]: TeamId[][] };
  overallStandings?: { [key: number]: TeamId[] };
  times: { [key: string]: { [key: string]: string } };
  teamStandingData: TeamStandingData;
  reffingTeams?: TeamId[];
  teamScores?: TeamScores;
  totalRoundRobinGames: { [tournamentId: string]: number };
  totalCompletedRoundRobinGames: { [tournamentId: string]: number };
  event: Event;
  tournaments: Tournament[];
}

export interface Tournament {
  id: number;
  eventScheduleId: number;
  ballTypeId: number;
  divisionId: number;
  gameNumberType: 'all-teams' | 'custom';
  gamePerTeams: number;
  numberPlayoffTeams?: number;
  numberOfTeams: number;
  numberOfPools: number;
  numberOfTiers: number;
  allTeams?: TeamId[];
  name?: string;
  forYouth?: boolean;
  pointsPerMatchWin?: number;
  bufferTime?: number;
  playoffBufferTime: number;
  gameTime?: number;
  requiredPlayerFields?: string[];
  displayedPlayerFields?: string[];
  playoffGameTime?: { [key in PlayoffStages]?: number };
  matchups?: {
    pool: number;
    color: string;
    fakeGameFor?: number;
    player1: TeamId;
    player2: TeamId;
  }[][][];
  standingsStrategy?: { label: string; id: string }[];
  notes?: string[];
  pools?: TeamId[][];
  schedule?: EventSchedule;
  teams?: EventTeam[];
  halftimeTime?: number;
  tieBreakerTime?: number;
  penaltyTime?: number;
  isRunningTime?: boolean;
  groupStandingsByPools?: boolean;
  stages?: string[];
}

export interface Opponent {
  teamId: string;
  isFake: boolean;
  isFakeForOpponent: boolean;
  pool: number;
  color: string;
  poolIndex: number;
  matchupIndex: number;
  matchIndex: number;
}

export type ScheduleSettings = Pick<
  EventSchedule,
  | 'manualScheduling'
  | 'times'
  | 'numberOfCourts'
  | 'numberConcurrentCourtPerTeam'
  | 'bufferTime'
  | 'playoffBufferTime'
  | 'gameTime'
  | 'playoffGameTime'
  | 'reffingTeams'
  | 'globalClock'
>;

export type EventSettings = Pick<
  Event,
  | 'website'
  | 'contactMethods'
  | 'signupUrl'
  | 'paymentEmail'
  | 'paymentType'
  | 'pricePerTeam'
  | 'currency'
  | 'streamUrl'
  | 'requiredDocuments'
>;

export type TournamentSettings = Pick<
  Tournament,
  | 'gameNumberType'
  | 'gamePerTeams'
  | 'numberOfTeams'
  | 'numberOfPools'
  | 'numberOfTiers'
  | 'name'
  | 'numberPlayoffTeams'
  | 'standingsStrategy'
  | 'pointsPerMatchWin'
  | 'bufferTime'
  | 'playoffBufferTime'
  | 'gameTime'
  | 'requiredPlayerFields'
  | 'displayedPlayerFields'
  | 'playoffGameTime'
  | 'halftimeTime'
  | 'tieBreakerTime'
  | 'forYouth'
  | 'penaltyTime'
  | 'isRunningTime'
  | 'groupStandingsByPools'
  | 'stages'
>;

export type Payment = {
  id: number;
  paymentId: string;
  amount: number;
  currency: string;
  status: string;
  customerId: string;
  description: string;
  paymentMethodType: string;
  refundStatus: string;
  metadata: any;
  createdAt: Date;
  userId?: number;
};

export type EventTeam = Team & {
  divisionId: number;
  ballTypeId: number;
  tournamentId: number;
  eventId: number;
  teamId?: number;
  payment?: Payment;
  isRegistration?: boolean;
  isWaitlist?: boolean;
  creatorId?: number;
  documentAcknowledgements?: DocumentAcknowledgement[];
};

export type DocumentAcknowledgement = {
  id: number;
  acknowledged: boolean;
  document: Document;
  documentId: number;
};

export interface RegisteredTeams {
  [tournamentId: string]: EventTeam[];
}

export type Team = {
  id: number;
  name: string;
  location?: any;
  players?: TeamPlayer[];
};

export type TeamId = {
  teamId: number;
  name?: string;
  position?: number;
  groupPosition?: { position: number; group: number };
  round?: number;
  match?: number;
  loser?: number;
  winner?: number;
};

export interface SelectedCourt {
  gameId: string;
  isBlocked: boolean;
  manuallyAdded: boolean;
}

export type Matchup = {
  type?: string;
  pool: number;
  gameId: string;
  originalRoundNumber: number;
  originalCourt: number;
  currentCourt: number;
  resolvedData?: { player1Id: number; player2Id: number; backupPlayer1Label: string; backupPlayer2Label: string };
  tier?: number;
  color: string;
  playoffStage?: 'quarters' | 'semis' | 'final';
  player1: TeamId;
  fakeGameFor?: number;
  player2: TeamId;
  round?: number;
  playoffGame?: number;
  match?: number;
  time: string;
  label?: string;
  bufferTime?: number;
  gameTime?: number;
  duration?: number;
  date: string;
  manuallyChanged?: boolean;
  manuallyAdded?: boolean;
  scheduleKey: string;
  initialScheduleKey?: string;
  originalScheduleKey?: string;
  tournamentId?: number;
  win?: { round: number; match: number };
};

export function isTeam(referee: EventReferee | TeamId | DynamicReferee): referee is TeamId {
  return (referee as TeamId).teamId !== undefined;
}

export function isDynamic(referee: EventReferee | TeamId | DynamicReferee): referee is DynamicReferee {
  return (referee as DynamicReferee).loser !== undefined || (referee as DynamicReferee).in !== undefined;
}

export function isError(referee: EventReferee | TeamId | DynamicReferee | ErrorReferee): referee is ErrorReferee {
  return (referee as ErrorReferee).type === 'error';
}

export type TeamScores = {
  [key: string]: { score: { for: number; against: number }; gameId: string }[];
};

export type TeamScore = TeamId & { score: number };
export type ScheduleRef = (EventReferee | TeamId | DynamicReferee) & {
  type: 'team' | 'official' | 'dynamic' | 'error';
};
export type ScheduleRefs = { [key: string]: ScheduleRef[] };
export type Score = {
  player1: TeamScore;
  player2: TeamScore;
  ignoreForStandings?: boolean;
  state: 'in-progress' | 'final';
};
export type Scores = { [key: string]: Score };
export interface DynamicReferee {
  loser?: 'previous_match';
  in?: number[];
}
export interface ErrorReferee {
  type: 'error';
}
export interface EventReferee {
  id: number;
  refereeId?: number;
  firstName: string;
  lastName: string;
  autoAdded?: boolean;
  role: string;
  approved?: boolean;
  ballTypeIds?: number[];
  event?: Event;
}
